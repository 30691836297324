import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './NotFound.css';
import NotFoundImg from '../../assets/images/NotFound.png';

const NotFound = ({ roleId }) => {
  return (
    <div
      className="xpa-align-items cds--tile"
      style={{ marginTop: '2em', marginLeft: '2em', marginRight: '2em' }}>
      <img className="xpa-image-size" src={NotFoundImg} alt="Not Found Image" />
      <h5>How did you end up here?</h5>
      <div style={{ marginTop: '1em' }}>Please contact support@x0pa.com</div>
    </div>
  );
};

export default withRouter(NotFound);
