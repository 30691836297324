import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FileUploaderButton,
  Tile,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from '@carbon/react';

import {
  uploadCandNrics,
  getCandAsessStatus,
  getCandAsessStatusReportByUuid,
} from '../../../actions/Assessments';

import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import SuccErrNotification from '../../../components/common/SuccErrNotification';
import SampleCandNricTemplate from '../../../assets/files/Cand_Nric_List_Template.xlsx';
import NoContentBox from '../../../components/common/NoContentBox';
import AutoLogout from './AutoLogout';
import PublicHeader from '../../../headers/PublicHeader';
import moment from 'moment';

class PartnerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { location, history } = this.props || {};
    const { state } = location || {};
    const { partnerEmailId } = state || {};
    const { push } = history || {};
    if (!partnerEmailId) {
      push('/partner/login');
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleGetCandAssessStatus = (exportData) => {
    const { location } = this.props || {};
    const { state } = location || {};
    const { partnerEmailId } = state || {};
    const { uploadedNRICs } = this.state;
    const data = {
      nricList: uploadedNRICs,
      email: partnerEmailId,
    };

    const updateState = {};
    if (exportData) {
      data.isExport = true;
      updateState.isExporting = true;
    }
    this.setState({ ...updateState }, () => {
      this.props
        .getCandAsessStatus(data)
        .then((res) => {
          if (res && !res.error) {
            if (exportData) {
              const { exportUuid } = res || {};
              this.interval = setInterval(() => {
                this.props
                  .getCandAsessStatusReportByUuid(exportUuid)
                  .then((res) => {
                    if (res && !res.error) {
                      const { url } = res || {};
                      if (url) {
                        window.open(url, '_self');
                        clearInterval(this.interval);
                        this.setState({ isExporting: false });
                      }
                    } else {
                      clearInterval(this.interval);
                      this.setState({
                        isExporting: false,
                        errorNotif: true,
                        errMsg:
                          'An Error has ocurred while processing your request, Please try again later',
                      });
                      setTimeout(() => {
                        this.setState({
                          errMsg: '',
                          errorNotif: false,
                        });
                      }, 5000);
                    }
                  })
                  .catch((e) => {
                    this.setState({
                      isExporting: false,
                      errorNotif: true,
                    });
                  });
              }, 4000);
            } else {
              this.setState({ candAssessStatusList: res || [] });
            }
          }
        })
        .catch((error) => {
          console.log('Error', error);
          this.setState({
            isExporting: false,
            errorNotif: true,
          });
        });
    });
  };

  handleUploadCandNrics = (element) => {
    const fileData = element.target.files;
    const currFile = new FormData();
    const ext = element.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ['xlsx'];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    currFile.append('attachment', fileData[0]);
    if (isFileAllowed) {
      this.props
        .uploadCandNrics(currFile)
        .then((res) => {
          const { uploadedNRICs } = res || {};
          if (
            res &&
            uploadedNRICs &&
            Array.isArray(uploadedNRICs) &&
            uploadedNRICs?.length > 0
          ) {
            this.setState(
              {
                uploadedNRICs,
                successNotif: true,
                notifMsg: 'Your details have been uploaded successfully',
              },
              () => {
                this.handleGetCandAssessStatus();
                setTimeout(() => {
                  this.setState({
                    notifMsg: '',
                    successNotif: false,
                  });
                }, 5000);
              }
            );
          } else {
            this.setState({
              errorNotif: true,
            });
          }
        })
        .catch((e) => {
          this.setState({
            errorNotif: true,
          });
        });
    } else {
      this.setState(
        {
          errorNotif: true,
          errMsg: 'Invalid format, Please upload a valid format.',
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorNotif: false,
              errMsg: '',
            });
          }, 5000);
        }
      );
    }
  };

  handleExport = () => {
    this.handleGetCandAssessStatus(true);
  };

  render() {
    const {
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      candAssessStatusList,
      isExporting,
    } = this.state;

    const isCandStatusListAvailable =
      candAssessStatusList &&
      Array.isArray(candAssessStatusList) &&
      candAssessStatusList.length > 0;

    const headers = [
      {
        key: 'header_0',
        header: 'S/No',
      },
      {
        key: 'header_1',
        header: 'Name',
      },
      {
        key: 'header_2',
        header: 'NRIC',
      },
      {
        key: 'header_3',
        header: 'PSC NEO',
      },
      {
        key: 'header_4',
        header: 'NEO',
      },
      {
        key: 'header_5',
        header: 'WGIII',
      },
      {
        key: 'header_6',
        header: 'ANRA',
      },
    ];

    const rows = isCandStatusListAvailable
      ? candAssessStatusList?.map((itm, idx) => {
          const {
            fullName,
            nric,
            gba: { status: gbaTestStatus, date: gbaTestDate } = {},
            neo: { status: neoTestStatus, date: neoTestDate } = {},
            pscNeo: { status: pscNeoStatus, date: pscNeoTestDate } = {},
            vi: { status: viTestStatus, date: viTestDate } = {},
            wgiii: { status: wgiiiTestStatus, date: wgiiiTestDate } = {},
            anra: { status: anraTestStatus, date: anraTestDate } = {},
          } = itm || {};
          return {
            id: `row_${nric}_${idx}`,
            header_0: <div className="text-capitalize ">{idx + 1}</div>,
            header_1: <div className="text-capitalize">{fullName}</div>,
            header_2: <div className="text-uppercase ">{nric || '-'}</div>,
            header_3: (
              <div className="text-capitalize ">
                {pscNeoStatus || '-'}{' '}
                {pscNeoTestDate &&
                  `(${moment(pscNeoTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
            header_4: (
              <div className="text-capitalize ">
                {neoTestStatus || '-'}{' '}
                {neoTestDate &&
                  `(${moment(neoTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
            header_5: (
              <div className="text-capitalize">
                {wgiiiTestStatus || '-'}{' '}
                {wgiiiTestDate &&
                  `(${moment(wgiiiTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
            header_6: (
              <div className="text-capitalize">
                {anraTestStatus || '-'}{' '}
                {anraTestDate &&
                  `(${moment(anraTestDate).format('DD MMM YYYY')})`}
              </div>
            ),
          };
        })
      : [];

    return (
      <>
        <div className="mt-3">
          <AutoLogout />
          <SuccErrNotification
            successNotif={successNotif}
            errorNotif={errorNotif}
            notifMsg={notifMsg}
            errMsg={errMsg}
          />
          <Tile>
            <h5 className="mb-2">Upload NRIC's</h5>
            <hr />
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="text-right">
                  <FileUploaderButton
                    buttonLabel="Upload"
                    labelText="Upload"
                    buttonKind="primary"
                    accept={['.xlsx']}
                    disableLabelChanges
                    name="file"
                    onChange={(e) => this.handleUploadCandNrics(e)}
                  />
                  <div className="small mt-2">.xlsx format is allowed</div>
                </div>
                <div className="ml-2">
                  <Button
                    size="sm"
                    className="txt-dec-none"
                    kind="tertiary"
                    href={SampleCandNricTemplate}>
                    Download Template
                  </Button>
                </div>
              </div>
              <div className="">
                <Button
                  kind="primary"
                  size="sm"
                  disabled={!isCandStatusListAvailable || isExporting}
                  onClick={() => this.handleExport()}>
                  {isExporting ? 'Exporting...' : 'Export'}
                </Button>
              </div>
            </div>
          </Tile>
          <div className="mt-3">
            {isCandStatusListAvailable ? (
              <Table size="lg" useZebraStyles={true}>
                <TableHead>
                  <TableRow>
                    {headers?.map((header) => (
                      <TableHeader id={header?.key} key={header?.header}>
                        {header?.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row) => (
                    <TableRow key={row?.id}>
                      {Object.keys(row)
                        ?.filter((key) => key !== 'id')
                        ?.map((key) => {
                          return <TableCell key={key}>{row?.[key]}</TableCell>;
                        })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <>
                <NoContentBox message="There are no candidates for your current selection." />
              </>
            )}
          </div>
          <JDProcessOverlay show={this.props.loading} message="processing..." />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
});

const mapDispatchToProps = {
  uploadCandNrics,
  getCandAsessStatus,
  getCandAsessStatusReportByUuid,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDashboard);
